<template>
  <div>
    <!-- Contenedor Bienvenida -->
    <div
      ref="bienvenidaRef"
      class="bienvenida-container"
    >
      <p class="bienvenida-text">
        {{ mensaje }}
      </p>
      <p
        v-if="mostrarMensajeFinal"
        class="textmia"
      >
        {{ mensajeFinal }}
      </p>
      <img
        :src="separadorImg"
        alt="Separador"
        class="separador-img"
      >
    </div>
  </div>
</template>

<script>
import { onMounted, ref, nextTick } from 'vue';
import 'animate.css'; // Asegúrate de importar animate.css
import separadorImg from '@/assets/separador1.png';

export default {
  name: 'BienvenidaPage',
  setup() {
    const bienvenidaRef = ref(null);
    const mensaje = ref(""); // Primer mensaje a mostrar
    const mensajeFinal = ref(""); // Segundo mensaje a mostrar
    const text = "Después de caminar juntos durante años llenos de amor y complicidad, hemos tomado la decisión de unir nuestras vidas en matrimonio. \n \n Y al mismo tiempo celebrar el bautizo \n de nuestra segunda y hermosa bebé";
    const textFinal = "Mía."; // Texto final a mostrar
    let hasStartedWriting = false;
    const animationDone = ref(false);
    const mostrarMensajeFinal = ref(false);

    const escribirTexto = (texto, index, callback) => {
      if (index < texto.length) {
        mensaje.value += texto.charAt(index);
        setTimeout(() => escribirTexto(texto, index + 1, callback), 50);
      } else if (callback) {
        callback();
      }
    };

    const escribirTextoFinal = (texto, index) => {
      if (index < texto.length) {
        mensajeFinal.value += texto.charAt(index);
        setTimeout(() => escribirTextoFinal(texto, index + 1), 50);
      }
    };

    onMounted(async () => {
      await nextTick();

      if (bienvenidaRef.value) {
        const element = bienvenidaRef.value;

        const observerOptions = {
          root: null,
          rootMargin: '0px',
          threshold: [0.1]
        };

        const observerCallback = (entries) => {
          entries.forEach(entry => {
            if (entry.isIntersecting) {
              if (!animationDone.value) {
                element.classList.add('animate__animated', 'animate__fadeInUp');
                element.addEventListener('animationend', () => {
                  element.classList.remove('animate__animated', 'animate__fadeInUp');
                  element.style.opacity = '1';
                }, { once: true });

                if (!hasStartedWriting) {
                  setTimeout(() => {
                    escribirTexto(text, 0, () => {
                      mostrarMensajeFinal.value = true;
                      escribirTextoFinal(textFinal, 0);
                    });
                  }, 500);
                  hasStartedWriting = true;
                }

                animationDone.value = true;
              }
            } else {
              element.style.opacity = '0';
              animationDone.value = false;
            }
          });
        };

        const observer = new IntersectionObserver(observerCallback, observerOptions);
        observer.observe(element);
      }
    });

    return { bienvenidaRef, mensaje, mensajeFinal, mostrarMensajeFinal, separadorImg };
  }
};
</script>


<style scoped>
/* Contenedor Bienvenida */
@font-face {
  font-family: 'Karla';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/karla/v31/qkBIXvYC6trAT55ZBi1ueQVIjQTDeJqaE0lK.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}


.bienvenida-container {
  position: relative;
  width: 100%;
  padding-top: 30px;
  padding-bottom: 0; /* Asegúrate de que no haya padding abajo */
  margin: 0; /* Elimina cualquier margen alrededor del contenedor */
  text-align: center;
  background: url('../assets/textura-fondo.jpg') no-repeat center center;

  background-color: rgba(44,43,48,255); /* Fondo del contenedor con 70% opacidad */
  background-size: cover; /* Ajusta el tamaño del fondo */
  background-position: center; /* Centra la imagen de fondo */
  opacity: 0;
  z-index: 1;
  display: flex;
  flex-direction: column; /* Asegúrate de que el texto se apile verticalmente */
  align-items: center; /* Centrar contenido */
}

/* Estilos para el texto de bienvenida */
.bienvenida-text {
  font-family: 'karla';/* Cambia la fuente a MonteCarlo */
  font-size: 16px; /* Ajusta el tamaño de la fuente como desees */
  color: #10100f; /* Cambia el color del texto si es necesario */
  margin: 0; /* Elimina margen para un mejor control del espaciado */
  margin-bottom: 40px;
 margin: 0 30px;
 white-space: pre-line;
}

/* Aplicar animación y mantener el estado final */
.animate__animated {
  animation-fill-mode: both; /* Mantiene el estado final de la animación */
}


@font-face {
  font-family: 'FeelingPassionate';
  src: url('@/assets/fonts/passionate.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: block; /* Espera a que la fuente esté cargada antes de mostrarla */
}

.textmia {
  font-family: 'FeelingPassionate';
  font-size: 34px;
  opacity: 1; /* Asegura que el texto esté visible */
  color:  #917087d4;
  margin-top: 10px;
  transition: opacity 0.3s ease;
}



</style>
