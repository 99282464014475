<template>
  <div>
    <!-- Contenedor Bienvenida Padres -->
    <div
      class="padres-container"
    >
      <div
        ref="texto1Ref"
        class="texto-container text"
      >
        Boda: <br>
      </div>

      <div
        ref="texto0Ref"
        
        class="texto-container texto-novia"
      >
        <span class="novia">
          Mónica Jared Domínguez </span>
        <br><span class="starlove">&</span><br>
        Jesús Nuñez Palacios
      </div>

      <!-- Texto 1 -->
   
      
      <!-- Texto 2 -->
      <div
        ref="texto2Ref"
        class="texto-container"
      >
        <span class="titulo-novia"><br>Bautizo: <br> </span> <br>
        <span class="nombre-novia">Mía Luisa </span> <br>
        <div class="icono-container">
          <i class="fas fa-heart icono" />
        </div>
      </div>

      <div
        ref="texto3Ref"
        class="texto-container"
      >
        <span class="titulo-novio" /> <br>
        <span class="nombre-novio" /> <br>
        <span class="nombre-novio" />
      </div>
    </div>
  </div>
</template>
    
  <script>
  import { onMounted, ref, nextTick } from 'vue';
  import 'animate.css';

  export default {
    name: 'BienvenidaPadresPage',
    setup() {
      const texto1Ref = ref(null);
      const texto2Ref = ref(null);
      const texto3Ref = ref(null);
      const texto0Ref = ref(null);

      const observeElement = (element) => {
        if (element.value) {
          const observerOptions = {
            root: null,
            rootMargin: '0px',
            threshold: [0.1],
          };
  
          const observerCallback = (entries) => {
            entries.forEach((entry) => {
              if (entry.isIntersecting) {
                element.value.classList.add('animate__animated', 'animate__fadeInUp');
                element.value.style.opacity = '1';
                element.value.style.transform = 'translateY(0)';
  
                element.value.addEventListener('animationend', () => {
                  element.value.classList.remove('animate__animated', 'animate__fadeInUp');
                }, { once: true });
              } else {
                element.value.style.opacity = '0';
                element.value.style.transform = 'translateY(50px)';
              }
            });
          };
  
          const observer = new IntersectionObserver(observerCallback, observerOptions);
          observer.observe(element.value);
        }
      };
  
      onMounted(async () => {
        await nextTick();
        observeElement(texto0Ref);

        observeElement(texto1Ref);
        observeElement(texto2Ref);
        observeElement(texto3Ref);
      });
  
      return { texto0Ref, texto1Ref, texto2Ref, texto3Ref };
    },
  };
  </script>
  
  <style scoped>
@font-face {
  font-family: 'FeelingPassionate';
  src: url('@/assets/fonts/passionate.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: block; /* Espera a que la fuente esté cargada antes de mostrarla */
}


@font-face {
  font-family: 'Starlove';
  src: url('../assets/fonts/starlove.ttf') format('truetype'); /* Asegúrate de usar la extensión correcta */
  font-weight: normal;
  font-style: normal;
}
  /* Contenedor Bienvenida Padres */
  .padres-container {
    position: relative;
    width: 100%;
    text-align: center;
    background-image: url('../assets/textura-fondo.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-top: 50px;
  }
  
  /* Estilo para los textos */
  .texto-container {
    padding: 5px;
    margin: 10px 0;
    opacity: 0;
    font-size: 20px;
    font-family: 'Dancing Script', sans-serif;
  font-weight: 350;    color: #333;
    border-radius: 10px;
    z-index: 2;
    width: 90%;
    
    transform: translateY(50px);
  }

  .novia {
    
    font-size: 20px;
    font-family: 'Dancing Script', sans-serif;
  font-weight: 350;    color: #333;
}
  
  .texto-container.animate__animated {
    transform: translateY(0);
    opacity: 1;
  }
  

  .nombre-icono-container {
  display: flex;
  align-items: center;
}

.icono {
  margin-left: 8px; /* Ajusta el espacio entre el texto y el ícono */
  color: #7e667b; /* Cambia el color según tu preferencia */
  font-size: 24px; /* Ajusta el tamaño del ícono */
}

/* Estilo para la palabra Novia */
.titulo-novia {
  font-size: 36px;
  font-family: 'FeelingPassionate';
margin-top:20px;
  font-weight: 350;    
  color: #917087d4;


  
}

/* Estilo para la palabra Novio */
.titulo-novio {
  font-size: 14px;

  color: #454444df; /* Cambia el color según lo desees */
  font-weight: bold; /* Negrita o estilo que prefieras */
  font-family: 'Roboto';

}
.text{
  font-size: 36px;
  font-family: 'FeelingPassionate';

  font-weight: 350;    color: #917087d4;
}

/* Estilo para los nombres de los novios */
.nombre-novia{
  font-family: 'Dancing Script', sans-serif;
  opacity: 1; /* Asegura que el texto esté visible */
  color: #755b71;
  margin-top: 50px;
  transition: opacity 0.3s ease;
  font-size: 30px;
}

.nombre-novio {
  font-family: 'tangerine', cursive;

  color: #10100f; /* Color para los nombres */
  font-weight: normal; /* Peso de letra normal para los nombres */

}

.starlove {
  margin-top:-30px;
  font-family: 'starlove', cursive; /* Cambia 'cursive' por la fuente por defecto si deseas */
  font-size: 44px; /* Ajusta el tamaño según lo necesites */
  color: #93806d; /* Ajusta el color según tu preferencia */
  top:-20px;
}




  </style>
  