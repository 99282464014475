<template>
  <div>
    <!-- Contenedor Bienvenida -->
    <div
      ref="bienvenidaRef"
      class="bienvenida-container"
    >
      <br> Nos alegra enormemente contar con su compañia para celebrar este momento tan significativo. <br><br> Les pedimos confirmar asistencia<br>
      <img
        :src="separadorImg"
        alt="Separador"
        class="separador-img"
      >
    </div>
  </div>
</template>

<script>
import { onMounted, ref, nextTick } from 'vue';
import 'animate.css'; // Asegúrate de importar animate.css
import separadorImg from '@/assets/separador1.png';

export default {
  name: 'BienvenidaPage',
  setup() {
    const bienvenidaRef = ref(null);
    const animationDone = ref(false); // Para controlar si la animación ya se ha hecho

    onMounted(async () => {
      await nextTick(); // Asegurarte de que el DOM esté completamente actualizado

      if (bienvenidaRef.value) {
        const element = bienvenidaRef.value;

        // Configuración del IntersectionObserver
        const observerOptions = {
          root: null, // Usar el viewport como raíz
          rootMargin: '0px', // Ajustar si es necesario
          threshold: [0.1] // Activar cuando el 10% del elemento sea visible
        };

        const observerCallback = (entries) => {
          entries.forEach(entry => {
            if (entry.isIntersecting) {
              if (!animationDone.value) {
                // Si el elemento es visible y la animación no ha sido realizada
                element.classList.add('animate__animated', 'animate__fadeInUp');
                element.addEventListener('animationend', () => {
                  element.classList.remove('animate__animated', 'animate__fadeInUp');
                  element.style.opacity = '1'; // Asegúrate de que el elemento se mantenga visible
                }, { once: true });

                // Marca la animación como realizada
                animationDone.value = true;
              }
            } else {
              // Si el elemento se vuelve invisible, resetea la animación
              element.style.opacity = '0'; // Ocultar el elemento cuando no es visible
              // Marca la animación como no realizada
              animationDone.value = false;
            }
          });
        };

        // Crear un nuevo IntersectionObserver
        const observer = new IntersectionObserver(observerCallback, observerOptions);
        observer.observe(element);
      }
    });

    return { bienvenidaRef, separadorImg };
  }
};
</script>

<style scoped>
/* Contenedor Bienvenida */
.bienvenida-container {
  position: relative;
  width: 100%;
  max-width: 800px;
  margin: 0 auto; /* Centra el contenedor horizontalmente */
  padding: 20px;
  text-align: center;
  background: url('../assets/foto.jpg') no-repeat center center;
  background-size: cover; /* Asegura que la imagen cubra el contenedor */
  opacity: 0;
  transform: translateY(0);
  z-index: 1;
  font-size: 18px;
  font-family: 'Karla', cursive;
  font-weight: 300;
  color: rgb(255, 255, 255);
  
  /* Flexbox para centrar el contenido */
  display: flex;
  flex-direction: column; /* Apila los elementos verticalmente */
  align-items: center; /* Centra horizontalmente */
}

/* Efecto de oscurecimiento en el fondo */
.bienvenida-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(96, 72, 98, 0.5); /* Fondo negro semitransparente */
  z-index: -1; /* Coloca el fondo detrás del contenido */
}

/* Aplicar el desenfoque a la imagen de fondo */
.bienvenida-container::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url('../assets/foto.jpg') no-repeat center center;
  background-size: cover; /* Asegura que la imagen cubra el contenedor */
  filter: blur(2px); /* Aplica el efecto de desenfoque */
  z-index: -2; /* Coloca el desenfoque detrás del contenido */
}

/* Centrar la imagen separador */
.separador-img {
  display: block; /* Asegura que la imagen ocupe el ancho completo */
  margin: 20px 0; /* Agrega márgenes superior e inferior */
}

/* Efecto de sombra para el texto */
.bienvenida-container {
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7); /* Sombra de texto */
}

/* Aplicar animación y mantener el estado final */
.animate__animated {
  animation-fill-mode: both; /* Mantiene el estado final de la animación */
}
</style>
