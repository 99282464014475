<template>
  <div>
    <!-- Contenedor Bienvenida -->
    <div class="bienvenida-container">
      <div class="overlay">
        <div class="tex1">
          Código de Vestimenta
        </div>
      </div>

      <!-- Segundo Overlay debajo del primero -->
      <div
        ref="bienvenidaRef"
        class="overlay2"
      >
        <div
          ref="rightIconRef"
          class="icon"
        >
          <img
            src="@/assets/traje.png"
            alt="icon-right"
            class="icon-image pulso" 
          >
        </div>
        <div
          ref="rightIconRef"
          class="icon"
        >
          <img
            src="@/assets/vestido.png"
            alt="icon-right"
            class="icon-image pulso" 
          >
        </div>
      </div>

      <div class="text2">
        Formal
      </div>
      <img
        :src="separadorImg"
        alt="Separador"
        class="separador-img"
      >
    </div>
  </div>
</template>

<script>
import { onMounted, ref, nextTick } from 'vue';
import separadorImg from '@/assets/separador1.png';

export default {
  name: 'BienvenidaPage',
  setup() {
    const bienvenidaRef = ref(null);
    
    onMounted(async () => {
      await nextTick(); // Asegurarte de que el DOM esté completamente actualizado
    });

    return { bienvenidaRef, separadorImg };
  }
};
</script>

<style scoped>
/* Contenedor Bienvenida */
.bienvenida-container {
  position: relative;
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
  margin: 0;
  background-color: rgba(255, 253, 253, 0.95);
  z-index: 1;
  font-family: 'Dancing Script', cursive;
  font-size: 26px;
  background: url('../assets/textura-fondo.jpg') no-repeat center center;
  font-weight: 300;
  text-align: center;
  display: flex;
  flex-direction: column;
  background-color: rgba(243,240,235,255); /* Ruta de la imagen de fondo */
  background-size: auto; /* Mantiene el tamaño real de la imagen */
  background-position: center; /* Centra la imagen en el contenedor */
  background-repeat: no-repeat; /* Evita que la imagen se repita */
  align-items: center;
  gap: 20px;
  box-sizing: border-box; /* Asegura que el padding no agregue más ancho */
  overflow-x: hidden; /* Evita el desbordamiento horizontal */
}

/* Contenedor Overlay */
.overlay, .overlay2 {
  border-radius: 15px;
  padding: 20px;
  width: 90%; /* Se ajusta al 90% del ancho de la pantalla */
  max-width: 600px;
  margin: 0 auto;
  display: flex;
  flex-direction: column; /* Cambia a columna para que el contenido se apile verticalmente */
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: 'Dancing Script', cursive;
  font-size: 32px;
  color: #917087d4;
  flex-direction: row; /* Alinea los iconos en una fila horizontal */
  margin-top:-50px;
}

/* Elementos dentro de overlay */
.tex1 {
  font-size: 24px;
  font-weight: 600;
  text-align: center; /* Alinea el texto horizontalmente al centro */
}

.text2 {
  font-size: 22px;
  font-weight: 400;
  margin-top:-30px;
  color: #1a1818cd;
}

/* Estilo del icono */
.icon {
  margin: 20px 0; /* Añade margen vertical a los iconos */
}

.icon-image {
  width: 60px;
  height: auto;
}

/* Animación de pulso */
@keyframes pulso {
  0%, 100% {
    transform: scale(1); /* Tamaño original */
  }
  50% {
    transform: scale(1.1); /* Aumenta el tamaño en un 10% */
  }
}

/* Aplicar animación de pulso */
.pulso {
  animation: pulso 1.5s infinite; /* Duración y que se repita infinitamente */
}

/* Centrar la imagen separador */
.separador-img {
  display: block; /* Asegura que la imagen ocupe el ancho completo */
  margin: 20px 0; /* Agrega márgenes superior e inferior */
}
</style>
